import { createReducer, on } from '@ngrx/store';

import { CouponActions } from './coupons.actions';
import { CouponState } from '../interfaces/coupons.interface';

const initialState: CouponState = {
  categories: null,
  loading: false,
  error: null,
  coupon: null,
  couponLoading: false,
  couponError: null,
  deleteLoading: false,
  deleteCoupon: false,
  deleteError: null,
  createLoading: false,
  createCoupon: false,
  createError: null,
  createSuccess: false,
  updateLoading: false,
  updateCoupon: false,
  updateError: null,
  updateSuccess: false,
};

export const couponReducer = createReducer(
  initialState,
  on(CouponActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(CouponActions.listSuccess, (state, { data }) => ({
    ...state,
    categories: data,
    loading: true,
    error: null,
  })),
  on(CouponActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CouponActions.coupon, (state) => ({
    ...state,
    coupon: null,
    couponLoading: false,
    couponError: null,
  })),
  on(CouponActions.couponSuccess, (state, { data }) => ({
    ...state,
    coupon: data,
    couponLoading: true,
    couponError: null,
  })),
  on(CouponActions.couponFail, (state, { error }) => ({
    ...state,
    coupon: null,
    couponLoading: false,
    couponError: error,
  })),
  on(CouponActions.deleteCoupon, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteCategories: false,
  })),
  on(CouponActions.deleteSuccess, (state) => ({
    ...state,
    deleteCategories: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(CouponActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteCategories: false,
  })),
  on(CouponActions.createCoupon, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createCoupon: false,
  })),
  on(CouponActions.createSuccess, (state) => ({
    ...state,
    createCoupon: true,
    createLoading: true,
    createError: null,
  })),
  on(CouponActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createCoupon: false,
  })),
  on(CouponActions.updateCoupon, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateCoupon: false,
  })),
  on(CouponActions.updateSuccess, (state) => ({
    ...state,
    updateCoupon: true,
    updateLoading: true,
    updateError: null,
  })),
  on(CouponActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateCoupon: false,
  })),
);

export const couponKey = 'coupon';
