import { Component, OnInit } from '@angular/core';
import { VPopoverRef } from 'src/app/shared/core/components/v-popover/v-popover-ref';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

export interface MegaMenuFeature {
  icon: string;
  label: string;
  route: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

@Component({
  selector: 'v-mega-menu',
  templateUrl: './mega-menu.component.html',
  standalone: true,
  imports: [MatButtonModule, NgFor, RouterLink, MatIconModule],
})
export class MegaMenuComponent implements OnInit {
  features: MegaMenuFeature[] = [
    {
      icon: 'mat:layers',
      label: 'שולחן עבודה',
      route: '',
    },
    {
      icon: 'mat:assignment',
      label: 'צ׳אט ארגוני',
      route: '',
    },
    {
      icon: 'mat:contact_support',
      label: 'תמיכה טכנית',
      route: '',
    },
    {
      icon: 'mat:contacts',
      label: 'אנשי קשר',
      route: '',
    },
    {
      icon: 'mat:assessment',
      label: 'מערכת משימות',
      route: '',
    },
    {
      icon: 'mat:book',
      label: 'תיעוד',
      route: '',
    },
  ];

  pages: MegaMenuPage[] = [
    {
      label: 'M1 - Catalogs',
      route: 'https://myofer-admin.m-1.app/#/dashboard',
    },
    {
      label: 'M1 - runner',
      route: '',
    },
    {
      label: 'M1 - Logistics',
      route: '',
    },
    {
      label: 'M1 - Store',
      route: '',
    },
    {
      label: 'Myofer Website',
      route: 'https://myofer.co.il',
    },
    {
      label: 'Octopus',
      route: 'https://account.mongodb.com/account/login?n=https%3A%2F%2Fcloud.mongodb.com%2Fv2%2F66451858d9453f35549037b5&nextHash=%23clusters&signedOut=true',
    },
    {
      label: 'Unicorn',
      route: 'https://cms.groo.co.il/salat2/login.php',
    },
  ];

  constructor(private popoverRef: VPopoverRef<MegaMenuComponent>) {}

  ngOnInit() {}

  goTo(link: string){
   window.open(link, '_blank');
   this.close();
  }
  close() {
    this.popoverRef.close();
  }
}
