import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EntityActions } from './clients.actions';

import { map, mergeMap } from 'rxjs/operators';
import { TableList, ClientsService } from '../services/clients.service';
import { EntityFormData } from '../interfaces/clients.interface';

@Injectable()
export class ClientsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.list),
      map(({ type: _type, ...params }: TableList & { type: string }) => params),
      mergeMap((params) => {
        return this.clientsService
          .list(params)
          .pipe(map((data) => EntityActions.listSuccess({ data })));
      }),
    ),
  );

  getClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entity),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.clientsService
          .getClient(params)
          .pipe(map((data) => EntityActions.entitySuccess({ data })));
      }),
    ),
  );

  // filters$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(EntityActions.filter),
  //     mergeMap(() => {
  //       return this.clientService
  //         .filters()
  //         .pipe(map((data) => EntityActions.filterSuccess({ data })));
  //     }),
  //   ),
  // );



  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<EntityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.clientsService
          .update(params as Partial<EntityFormData>)
          .pipe(map((data) => EntityActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private clientsService: ClientsService,
  ) {}
}
