import { NgModule } from '@angular/core';
import { VHighlightDirective } from './v-highlight.directive';
import { V_HIGHLIGHT_OPTIONS, VHighlightOptions } from './v-highlight.model';
/**
 * Import every language you wish to highlight here
 * NOTE: The name of each language must match the file name its imported from
 */
import xml from 'highlight.js/lib/languages/xml';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { VHighlightService } from './v-highlight.service';

/**
 * Import every language you wish to highlight here
 * NOTE: The name of each language must match the file name its imported from
 */
export function hljsLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
  ];
}

@NgModule({
  providers: [
    {
      provide: V_HIGHLIGHT_OPTIONS,
      useValue: {
        languages: hljsLanguages,
      } as VHighlightOptions,
    },
    VHighlightService,
  ],
  imports: [VHighlightDirective],
  exports: [VHighlightDirective],
})
export class VHighlightModule {}
