<v-base-layout *ngIf="config$ | async as config">
  <v-progress-bar></v-progress-bar>

  <v-search></v-search>

  <mat-sidenav-container class="v-layout-sidenav-container">
    <mat-sidenav
      (closedStart)="onSidenavClosed()"
      [disableClose]="!!(sidenavDisableClose$ | async)"
      [fixedInViewport]="!!(sidenavFixedInViewport$ | async)"
      [mode]="(sidenavMode$ | async) ?? 'side'"
      [opened]="!!(sidenavOpen$ | async)"
    >
      <v-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></v-sidenav>
    </mat-sidenav>

    <mat-sidenav
      (closedStart)="onQuickpanelClosed()"
      [opened]="!!(quickpanelOpen$ | async)"
      class="v-layout-quickpanel"
      mode="over"
      position="end"
    >
      <v-quickpanel></v-quickpanel>
    </mat-sidenav>

    <mat-sidenav-content class="v-layout-sidenav-content">
      <v-toolbar
        [class.dark]="config.layout === 'vertical'"
        class="v-toolbar"
      ></v-toolbar>

      <main class="v-layout-content">
        <router-outlet></router-outlet>
      </main>

      <v-footer *ngIf="config.footer.visible" class="v-footer"></v-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <v-config-panel-toggle
    *ngIf="config.footer.visible"
    (openConfig)="configpanel.open()"
  ></v-config-panel-toggle>

  <!-- CONFIGPANEL -->
  <v-sidebar
    #configpanel
    [invisibleBackdrop]="true"
    [opened]="!!(configPanelOpen$ | async)"
    position="right"
  >
    <v-config-panel></v-config-panel>
  </v-sidebar>
  <!-- END CONFIGPANEL -->
</v-base-layout>
