<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-6 flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="assets/img/logo/logo.svg" />
    <div
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      <h1>
        {{ title$ | async }}
      </h1>
      <p>
        {{ subTitle$ }}
      </p>
    </div>
  </a>

  <!-- <button
    *ngIf="isHorizontalLayout$ | async"
    [class.hidden]="!(isDesktop$ | async)"
    [matMenuTriggerFor]="addNewMenu"
    color="primary"
    mat-flat-button
    type="button"
  >
    Add New
  </button> -->

  <mat-menu
    #addNewMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below"
  >
    <!-- <button mat-menu-item>
      <mat-icon svgIcon="mat:local_parking"></mat-icon>
      <span>Add Parking</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:local_mall"></mat-icon>
      <span>Add Mall</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button> -->

    <!-- <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button> -->
  </mat-menu>

  <!-- <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu> -->

  <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button
      (click)="openMegaMenu(megaMenuOriginRef)"
      *ngIf="isHorizontalLayout$ | async"
      [class.hidden]="!(isDesktop$ | async)"
      [ngClass]="{ 'bg-primary-600/10': megaMenuOpen$ | async }"
      color="primary"
      mat-button
      type="button"
    >
    <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
      <span>תפריט</span>

    </button>
  </div>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center"
  >
    <v-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"
    ></v-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <v-toolbar-notifications></v-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <button mat-menu-item (click)="navigateToLogin()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </div>
</div>

<v-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"
></v-navigation>
