import { Injectable, inject } from '@angular/core';
import {
  Router,
  CanActivateFn,
  UrlTree,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (this.authService.isAccessTokenExpired()) {
      return this.authService.refreshToken().pipe(
        map(() => true), // If token refresh is successful, allow access
        catchError(() => {
          this.authService.clearAccessToken();
          this.router.navigate(['/login']);
          return of(false); // If token refresh fails, redirect to login
        })
      );
    } else {
      return of(true); // If token is not expired, allow access
    }
  }

  loginGuard(): Observable<boolean | UrlTree> {
    if (this.authService.isAccessTokenExpired()) {
      return this.authService.refreshToken().pipe(
        map(() => {
          this.router.navigate(['/']);
          return true; // Redirect to home if token is refreshed
        }),
        catchError(() => {
          this.authService.clearAccessToken();
          this.router.navigate(['/login']);
          return of(true); // If refresh fails, redirect to login
        })
      );
    } else {
      this.router.navigate(['/']);
      return of(true); // If token is not expired, redirect to home
    }
  }

  roleGuard(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const accessibleRoles: string[] = route.data['roles'] || [];
    return from(this.authService.getTokenRole(accessibleRoles)).pipe(
      map((role) => !!role || this.router.parseUrl('/unauthorized')), // Return true if role is accessible, otherwise redirect
      catchError(() => of(this.router.parseUrl('/unauthorized'))) // If there's an error, redirect to unauthorized
    );
  }
}

export const AuthGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return inject(PermissionsService).canActivate();
};

export const LoginGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return inject(PermissionsService).loginGuard();
};

export const RoleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  return inject(PermissionsService).roleGuard(route);
};
