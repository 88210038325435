import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParkingService {
  relativePath = `${environment.apiUrl}/whitelist`;
  constructor(private http: HttpClient) {}

  public list(params: { offset: number; limit: number }) {
    return this.http.get(`${this.relativePath}/getList`, {
      params,
    });
  }

  public delete(licensePlates: string[]) {
    return this.http.delete(`${this.relativePath}/deleteFromWhitelist`, {
      body: { licenseplates: licensePlates },
    });
  }

  public create(licensePlates: string[]) {
    return this.http.post(`${this.relativePath}/addToWhitelist`, {
      licenseplates: licensePlates,
    });
  }
}
