import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  Provider,
} from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { VSplashScreenService } from './services/v-splash-screen.service';
import { VLayoutService } from './services/v-layout.service';
import { VDemoService } from './services/v-demo.service';
import { VPlatformService } from './services/v-platform.service';
import { VConfig, VThemeProvider } from './config/v-config.interface';
import { V_CONFIG, V_THEMES } from './config/config.token';
import { VHighlightModule } from './components/v-highlight/v-highlight.module';

export function provideV(options: {
  config: VConfig;
  availableThemes: VThemeProvider[];
}): (Provider | EnvironmentProviders)[] {
  return [
    importProvidersFrom(VHighlightModule),
    {
      provide: V_CONFIG,
      useValue: options.config,
    },
    {
      provide: V_THEMES,
      useValue: options.availableThemes,
    },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } satisfies MatFormFieldDefaultOptions,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(VSplashScreenService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(VLayoutService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(VPlatformService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(VDemoService),
      multi: true,
    },
  ];
}
