<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && (collapsedOpen$ | async)"
  class="v-sidenav flex flex-col"
>
  <div class="v-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img
        [src]="imageUrl$ | async"
        alt="Logo"
        class="w-6 select-none flex-none"
      />
      <div class="v-sidenav-toolbar__headline flex-auto">
        <h2>
          {{ title$ | async }}
        </h2>
        <p>
          {{ subTitle$ }}
        </p>
      </div>
      <button
        (click)="toggleCollapse()"
        *ngIf="showCollapsePin$ | async"
        class="v-sidenav-toolbar__collapse-toggle -mr-4 leading-none flex-none hidden lg:block"
        mat-icon-button
        type="button"
      >
        <mat-icon
          *ngIf="!collapsed"
          class="icon-xs"
          svgIcon="mat:radio_button_checked"
        ></mat-icon>
        <mat-icon
          *ngIf="collapsed"
          class="icon-xs"
          svgIcon="mat:radio_button_unchecked"
        ></mat-icon>
      </button>
    </div>

    <div *ngIf="searchVisible$ | async" class="v-sidenav-search__container">
      <div
        (click)="openSearch()"
        class="v-sidenav-search relative"
        matRipple
        matRippleColor="var(--v-sidenav-item-ripple-color)"
      >
        <mat-icon
          class="flex-none v-sidenav-search__icon"
          svgIcon="mat:search"
        ></mat-icon>
        <div class="flex-auto v-sidenav-search__placeholder">Quick Search</div>
        <div class="flex-none v-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div>

    <div class="v-sidenav-toolbar__divider"></div>
  </div>

  <v-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <v-sidenav-item
        *ngFor="let item of items$ | async; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></v-sidenav-item>
    </div>

    <div
      class="v-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none"
    >
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none" />
    </div>
  </v-scrollbar>

  <div *ngIf="userVisible$ | async" class="v-sidenav-user__container flex-none">
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.v-sidenav-user--open]="userMenuOpen$ | async"
      class="v-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--v-sidenav-item-ripple-color)"
    >
      <img
        alt="User Avatar"
        class="v-sidenav-user__image flex-none"
        src="../../../../assets/img/avatars/1.jpg"
      />
      <div class="v-sidenav-user__content flex-auto">
        <div class="v-sidenav-user__title">David Smith</div>
        <div class="v-sidenav-user__subtitle">Product Manager</div>
      </div>
      <mat-icon
        class="v-sidenav-user__dropdown-icon flex-none"
        svgIcon="mat:arrow_drop_down"
      ></mat-icon>
    </div>
  </div>
</div>
