import { createFeature, createReducer, on } from '@ngrx/store';

import { CounterComponentActions, SnackbarActions } from './counter.actions';

const initialState = {
  counter: 0
};

const counterFeature = createFeature({
  name: 'counter',
  reducer: createReducer(
    initialState,
    on(CounterComponentActions.increment, state =>
        ({ counter: state.counter + 1 })
    ),
    on(CounterComponentActions.decrement, state =>
      ({ counter: state.counter - 1 })
    ),
    on(CounterComponentActions.reset, state =>
        ({counter: 0 })
    ),
    on(SnackbarActions.displaySnackBar, state =>
      (state)
    ),
  ),
});

export const {
  name: counterFeatureKey,
  reducer: counterReducer,
} = counterFeature;

