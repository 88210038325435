import { Injectable } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NavigationEnd, Router } from '@angular/router';
import { VConfigService } from '../config/v-config.service';
import { filter } from 'rxjs/operators';

import {
  VColorScheme,
  VConfigName,
  VTheme,
} from '../config/v-config.interface';

@Injectable({
  providedIn: 'root',
})
export class VDemoService {
  constructor(
    private readonly router: Router,
    private readonly configService: VConfigService,
  ) {
    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        const route = this.router.routerState.root.snapshot;
        if (route.queryParamMap.has('layout')) {
          this.configService.setConfig(
            route.queryParamMap.get('layout') as VConfigName,
          );
        }

        if (route.queryParamMap.has('style')) {
          this.configService.updateConfig({
            style: {
              colorScheme: route.queryParamMap.get('style') as VColorScheme,
            },
          });
        }

        // TODO: Adjust primaryColor queryParam and see where it was used?
        const theme: VTheme | null = route.queryParamMap.get(
          'theme',
        ) as VTheme | null;
        if (theme) {
          this.configService.updateConfig({
            style: {
              themeClassName: theme,
            },
          });
        }

        if (route.queryParamMap.has('rtl')) {
          this.configService.updateConfig({
            direction: coerceBooleanProperty(route.queryParamMap.get('rtl'))
              ? 'rtl'
              : 'ltr',
          });
        }
      });
  }
}
