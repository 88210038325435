// loader.component.ts
import { Component,ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './loading.state'; // Update this path to your AppState definition
import { selectIsLoading } from './loading.selectors'; // Update this path to your selector file
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'app-loader',
  imports: [NgIf, CommonModule],
  template: `
    <div class="loader-overlay" *ngIf="loading$ | async">
    <img src="https://storage.googleapis.com/myofer-cms-prod-bucket/loader2.gif" width="250px" height="250px" alt="Loading..." class="loader" />
    </div>
  `,
  styles: [
    `
      .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      .loader {
        width: 150px; /* Adjust size as needed */
        height: auto;
      }
    `
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent {
  loading$ = this.store.select(selectIsLoading);

  constructor(private store: Store<AppState>) {}
}
