import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { DeepPartial } from '../interfaces/deep-partial.type';
import { mergeDeep } from '../utils/merge-deep';
import { VLayoutService } from '../services/v-layout.service';
import { vConfigs } from './v-configs';
import {
  VColorScheme,
  VConfig,
  VConfigName,
  VConfigs,
  VThemeProvider,
} from './v-config.interface';
import { CSSValue } from '../interfaces/css-value.type';
import { map } from 'rxjs/operators';
import { V_CONFIG, V_THEMES } from './config.token';

@Injectable({
  providedIn: 'root',
})
export class VConfigService {
  readonly configMap: VConfigs = vConfigs;
  readonly configs: VConfig[] = Object.values(this.configMap);
  private _configSubject = new BehaviorSubject<VConfig>(this.config);

  constructor(
    @Inject(V_CONFIG) private readonly config: VConfig,
    @Inject(V_THEMES) private readonly themes: VThemeProvider[],
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly layoutService: VLayoutService,
  ) {
    this.config$.subscribe((config) => this._updateConfig(config));
  }

  get config$(): Observable<VConfig> {
    return this._configSubject.asObservable();
  }

  select<R>(selector: (config: VConfig) => R): Observable<R> {
    return this.config$.pipe(map(selector));
  }

  setConfig(configName: VConfigName) {
    const settings = this.configMap[configName];

    if (!settings) {
      throw new Error(`Config with name '${configName}' does not exist!`);
    }

    this._configSubject.next(settings);
  }

  updateConfig(config: DeepPartial<VConfig>) {
    this._configSubject.next(
      mergeDeep({ ...this._configSubject.getValue() }, config),
    );
  }

  private _updateConfig(config: VConfig): void {
    this._setLayoutClass(config.bodyClass);
    this._setStyle(config.style);
    this._setDensity();
    this._setDirection(config.direction);
    this._setSidenavState(config.sidenav.state);
    this._emitResize();
  }

  private _setStyle(style: VConfig['style']): void {
    /**
     * Set light/dark mode
     */
    switch (style.colorScheme) {
      case VColorScheme.LIGHT:
        this.document.body.classList.remove(VColorScheme.DARK);
        this.document.body.classList.add(VColorScheme.LIGHT);
        break;

      case VColorScheme.DARK:
        this.document.body.classList.remove(VColorScheme.LIGHT);
        this.document.body.classList.add(VColorScheme.DARK);
        break;
    }

    /**
     * Set theme class
     */
    this.document.body.classList.remove(...this.themes.map((t) => t.className));
    this.document.body.classList.add(style.themeClassName);

    /**
     * Border Radius
     */
    this.document.body.style.setProperty(
      '--v-border-radius',
      `${style.borderRadius.value}${style.borderRadius.unit}`,
    );

    const buttonBorderRadius: CSSValue =
      style.button.borderRadius ?? style.borderRadius;
    this.document.body.style.setProperty(
      '--v-button-border-radius',
      `${buttonBorderRadius.value}${buttonBorderRadius.unit}`,
    );
  }

  private _setDensity(): void {
    if (!this.document.body.classList.contains('v-mat-dense-default')) {
      this.document.body.classList.add('v-mat-dense-default');
    }
  }

  /**
   * Emit event so charts and other external libraries know they have to resize on layout switch
   * @private
   */
  private _emitResize(): void {
    if (window) {
      window.dispatchEvent(new Event('resize'));
      setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    }
  }

  private _setDirection(direction: 'ltr' | 'rtl') {
    this.document.body.dir = direction;
  }

  private _setSidenavState(sidenavState: 'expanded' | 'collapsed'): void {
    sidenavState === 'expanded'
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  private _setLayoutClass(bodyClass: string): void {
    this.configs.forEach((c) => {
      if (this.document.body.classList.contains(c.bodyClass)) {
        this.document.body.classList.remove(c.bodyClass);
      }
    });

    this.document.body.classList.add(bodyClass);
  }
}
