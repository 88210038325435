import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EntityFormData } from '../interfaces/clients.interface';

export interface TableList {
  offset: number;
  limit: number;
  first_name?: string;
  email?: string;
  cellphone?: string;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: HttpClient) {}

  public list(params: TableList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/clients/`, {
      params: httpParams,
    });
  }

  public getClient(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/clients/${params.id}`,
    );
  }

  public filters() {
    return {};//this.http.get(`${environment.apiUrl}/clients/filters`);
  }

  public update(params: Partial<EntityFormData>) {
    return this.http.put(`${environment.apiUrl}/clients/`, { ...params });
  }
}
