import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { CategoryList } from '../services/category.service';
import { CategoryFormData } from '../interfaces/category.interface';

export const CategoryActions = createActionGroup({
  source: 'Category Component',
  events: {
    list: props<CategoryList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    category: props<{ id: number }>(),
    categorySuccess: props<{ data: any }>(),
    categoryFail: props<{ error: any }>(),
    categoriesChannel: emptyProps(),
    categoriesChannelSuccess: props<{ data: any }>(),
    categoriesChannelFail: props<{ error: any }>(),
    categoriesParent: props<{ categoryId?: number; single?: boolean }>(),
    categoriesParentSuccess: props<{ data: any }>(),
    categoriesParentFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<CategoryFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<CategoryFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
