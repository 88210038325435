import { createReducer, on } from '@ngrx/store';

import { RegisterAction } from './register.actions';

export interface RegisterState {
  register: {
    message: string;
    user: { id: number; email: string; createdAt: Date; updatedAt: Date };
  } | null;
  loading: boolean;
  error: any;
}

const initialState: RegisterState = {
  register: null,
  loading: false,
  error: null,
};

export const registerReducer = createReducer(
  initialState,
  on(RegisterAction.register, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(RegisterAction.registerSuccess, (state, { data }) => ({
    ...state,
    register: data,
    loading: true,
    error: null,
  })),
  on(RegisterAction.registerFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);

export const registerFeatureKey = 'register';
