import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EntityFormData } from '../interfaces/dashboard.interface';

export interface TableList {
  offset: number;
  limit: number;
  order_id?: number;
  created_on?: string;
  user_id?: number;
  status?: string;
  delivery_sum?: string;
  money_paid?: string;
  real_discount?: string;
  coupon_code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public list(params: TableList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/dashboards/radc`, {
      params: httpParams,
    });
  }

  public getDashboard(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/dashboards/radc/${params.id}`,
    );
  }
  public update(params: Partial<EntityFormData>) {
    return this.http.put(`${environment.apiUrl}/clients/`, { ...params });
  }
  public filters() {
    this.http.get(`${environment.apiUrl}/clients/filters`);
  }
}
