import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CouponActions } from './coupons.actions';

import { map, mergeMap } from 'rxjs/operators';
import { CouponList, CouponService } from '../services/coupons.service';
import { CouponFormData } from '../interfaces/coupons.interface';

@Injectable()
export class CouponsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponActions.list),
      map(
        ({ type: _type, ...params }: CouponList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.couponService
          .list(params)
          .pipe(map((data) => CouponActions.listSuccess({ data })));
      }),
    ),
  );

  getCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponActions.coupon),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.couponService
          .getCoupon(params)
          .pipe(map((data) => CouponActions.couponSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponActions.deleteCoupon),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.couponService
          .deleteCoupon(param.ids)
          .pipe(map((data) => CouponActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponActions.createCoupon),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CouponFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.couponService
          .createCoupon(params as Partial<CouponFormData>)
          .pipe(map((data) => CouponActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponActions.updateCoupon),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CouponFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.couponService
          .updateCoupon(params as Partial<CouponFormData>)
          .pipe(map((data) => CouponActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private couponService: CouponService,
  ) {}
}
