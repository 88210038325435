import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
    AUTHORIZATION: '',
  });

  constructor(private http: HttpClient) {}

  public register(params: { email: string; password: string }) {
    return this.http.post(`${environment.apiUrl}/user/register`, params, {
      headers: this.headers,
    });
  }
}
