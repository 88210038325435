import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  ignoreRoutes = [
    '/user/refresh',
    '/user/login',
    '/user/2fa',
    '/user/logout',
    '/user/register',
  ];
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      (request.url.startsWith(environment.apiUrl) &&
      !this.ignoreRoutes.some((t) =>
        request.url.startsWith(`${environment.apiUrl}${t}`),
      ))
    ) {
      if (this.authService.isAccessTokenExpired()) {
        return this.authService.refreshToken().pipe(
          switchMap(() => {
            const accessToken: string =
              this.authService.getAccessToken() as string;
            const requestWithToken = this.addTokenToRequest(
              request,
              accessToken,
            );
            return next.handle(requestWithToken);
          }),
          catchError(() => {
            this.authService.clearAccessToken();
            this.router.navigate(['/login']);

            return throwError(() => new Error('Token expired'));
          }),
        );
      }
      const accessToken: string = this.authService.getAccessToken() as string;
      const requestWithToken = this.addTokenToRequest(request, accessToken);
      return next.handle(requestWithToken);
    }
    return next.handle(request);
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string,
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: token,
      },
    });
  }
}
