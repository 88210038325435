import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoginService } from './login.service';
import { LoginAction } from './login.actions';

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private loginService: LoginService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.login),
      mergeMap((action) =>
        this.loginService.login(action).pipe(
          map((data) => LoginAction.loginSuccess({ data })),
          catchError((error) => of(LoginAction.loginFail({ error })))
        )
      )
    )
  );

  login2Fa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginAction.login2Fa),
      mergeMap((action) =>
        this.loginService.login2Fa(action).pipe(
          map((data) => LoginAction.login2FaSuccess({ data })),
          catchError((error) => of(LoginAction.login2FaFail({ error })))
        )
      )
    )
  );
}
