import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
    AUTHORIZATION: '',
  });

  constructor(private http: HttpClient) {}

  public login(params: { email: string; password: string }) {
    return this.http.post(`${environment.apiUrl}/user/login`, params, {
      withCredentials: true,
      headers: this.headers,
    });
  }

  public login2Fa(params: { code: number; tmpToken: string }) {
    return this.http.post(`${environment.apiUrl}/user/2fa`, params, {
      withCredentials: true,
      headers: this.headers,
    });
  }
}
