import { createActionGroup, props } from '@ngrx/store';
import { UsersList } from '../services/users.service';
import { UserFormData } from '../interfaces/users.interface';

export const UsersActions = createActionGroup({
  source: 'Users Component',
  events: {
    list: props<UsersList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    user: props<{ id: number }>(),
    userSuccess: props<{ data: any }>(),
    userFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<UserFormData>>(),
    createSuccess: props<Partial<UserFormData>>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<UserFormData>>(),
    updateSuccess: props<Partial<UserFormData>>(),
    updateFail: props<{ error: any }>(),
  },
});
