import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EntityActions } from './orders.actions';

import { map, mergeMap } from 'rxjs/operators';
import { TableList, OrdersService } from '../services/orders.service';

@Injectable()
export class OrdersEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.list),
      map(({ type: _type, ...params }: TableList & { type: string }) => params),
      mergeMap((params) => {
        return this.ordersService
          .list(params)
          .pipe(map((data) => EntityActions.listSuccess({ data })));
      }),
    ),
  );

  itemsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.itemsList),
      map(({ type: _type, ...params }: TableList & { type: string }) => params),
      mergeMap((params) => {
        return this.ordersService
          .itemsList(params)
          .pipe(map((data) => EntityActions.listSuccess({ data })));
      }),
    ),
  );

  getOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entity),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.ordersService
          .getOrder(params)
          .pipe(map((data) => EntityActions.entitySuccess({ data })));
      }),
    ),
  );
  constructor(
    private actions$: Actions,
    private ordersService: OrdersService,
  ) {}
}
