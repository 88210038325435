import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface TableList {
  offset: number;
  limit: number;
  id?: number;
  user_id?: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  public list(params: TableList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/orders/`, {
      params: httpParams,
    });
  }

  public itemsList(params: TableList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/orders/${params.id}`, {
    });
  }

  public getOrder(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/orders/${params.id}`,
    );
  }

  public filters() {
    return {};//this.http.get(`${environment.apiUrl}/clients/filters`);
  }
}
