import { Component, OnInit, TemplateRef } from '@angular/core';
import { VPopoverContent, VPopoverRef } from './v-popover-ref';
import { popoverAnimation } from '../../animations/popover.animation';
import {
  NgComponentOutlet,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';

@Component({
  selector: 'v-popover',
  templateUrl: './v-popover.component.html',
  styleUrls: ['./v-popover.component.scss'],
  animations: [popoverAnimation],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet, NgComponentOutlet],
})
export class VPopoverComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: VPopoverContent;
  context: any;

  constructor(private popoverRef: VPopoverRef) {}

  ngOnInit() {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
