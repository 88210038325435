import { createReducer, on } from '@ngrx/store';

import { ChainsActions } from './chains.actions';
import { ChainsState } from '../interfaces/chains.interface';

const initialState: ChainsState = {
  chains: null,
  loading: false,
  error: null,
  suppliers: null,
  suppliersLoading: false,
  suppliersError: null,
  chain: null,
  relatedStores: [],
  chainLoading: false,
  chainError: null,
  deleteLoading: false,
  deleteChains: false,
  deleteError: null,
  createLoading: false,
  createChains: false,
  createError: null,
  updateLoading: false,
  updateChains: false,
  updateError: null,
};

export const chainsReducer = createReducer(
  initialState,
  on(ChainsActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(ChainsActions.listSuccess, (state, { data }) => ({
    ...state,
    chains: data,
    loading: true,
    error: null,
  })),
  on(ChainsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(ChainsActions.suppliers, (state) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: null,
  })),
  on(ChainsActions.suppliersSuccess, (state, { data }) => ({
    ...state,
    suppliers: data,
    suppliersLoading: true,
    suppliersError: null,
  })),
  on(ChainsActions.suppliersFail, (state, { error }) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: error,
  })),
  on(ChainsActions.chain, (state) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: null,
  })),
  on(ChainsActions.chainSuccess, (state, { data }) => ({
    ...state,
    chain: data,
    chainLoading: true,
    chainError: null,
  })),
  on(ChainsActions.chainFail, (state, { error }) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: error,
  })),
  on(ChainsActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteChains: false,
  })),
  on(ChainsActions.deleteSuccess, (state) => ({
    ...state,
    deleteChains: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(ChainsActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteChains: false,
  })),
  on(ChainsActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createChains: false,
  })),
  on(ChainsActions.createSuccess, (state) => ({
    ...state,
    createChains: true,
    createLoading: true,
    createError: null,
  })),
  on(ChainsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createChains: false,
  })),
  on(ChainsActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateChains: false,
  })),
  on(ChainsActions.updateSuccess, (state) => ({
    ...state,
    updateChains: true,
    updateLoading: true,
    updateError: null,
  })),
  on(ChainsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateChains: false,
  })),
);

export const chainsFeatureKey = 'chains';
