import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserFormData } from '../interfaces/users.interface';

export interface UsersList {
  offset: number;
  limit: number;
  name?: string;
  id?: string;
  supplier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  public list(params: UsersList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/users/`, {
      params: httpParams,
    });
  }

  public getUser(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/users/${params.id}`,
    );
  }

  public delete(params: { id: number }) {
    return this.http.delete(`${environment.apiUrl}/users/${params.id}`);
  }

  public create(params: Partial<UserFormData>) {
    return this.http.post(`${environment.apiUrl}/users/`, params);
  }

  public update(params: Partial<UserFormData>) {
    console.log(params)
    return this.http.put(`${environment.apiUrl}/users/${params.id}`, { ...params });
  }
}
