// loading.reducer.ts
import { createReducer, on, Action } from '@ngrx/store';
import { startLoading, stopLoading } from './loading.actions';

export const initialState = false;

const _loadingReducer = createReducer(
  initialState,
  on(startLoading, () => true),
  on(stopLoading, () => false)
);

export function loadingReducer(state: boolean | undefined, action: Action) {
  return _loadingReducer(state, action);
}
