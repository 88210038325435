import { createActionGroup, props } from '@ngrx/store';
import { StoresList } from '../services/stores.service';
import { StoreFormData } from '../interfaces/stores.interface';

export const StoresActions = createActionGroup({
  source: 'Stores Component',
  events: {
    list: props<StoresList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    store: props<{ id: number | undefined }>(),
    storeSuccess: props<{ data: any }>(),
    storeFail: props<{ error: any }>(),
    suppliers: props<{ offset: number; limit: number; name?: string }>(),
    suppliersSuccess: props<{ data: any }>(),
    suppliersFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<StoreFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<StoreFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    image: props<{
      files: {
        fileType: string;
        file: File;
        index: number;
        name: string;
      }[];
      folder: string;
    }>(),
    imageSuccess: props<{ data: string[] }>(),
    imageFail: props<{ error: any }>(),
  },
});
