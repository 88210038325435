import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { StoresActions } from './stores.actions';

import { map, mergeMap } from 'rxjs/operators';
import { StoresList, StoresService } from '../services/stores.service';
import { StoreFormData } from '../interfaces/stores.interface';

@Injectable()
export class StoresEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.list),
      map(
        ({ type: _type, ...params }: StoresList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.storesService
          .list(params)
          .pipe(map((data) => StoresActions.listSuccess({ data })));
      }),
    ),
  );

  getStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.store),
      map(({ ...params }: { id: number | undefined }) => params),
      mergeMap((params) => {
        return this.storesService
          .getStore(params)
          .pipe(map((data) => StoresActions.storeSuccess({ data })));
      }),
    ),
  );

  suppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.suppliers),
      map(({ type: _type, ...params }) => params),
      mergeMap((params) => {
        return this.storesService.suppliers(params).pipe(
          map((data) => {
            console.log('data1111111111');
            console.log(data);
            return StoresActions.suppliersSuccess({ data });
          }),
        );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.storesService
          .delete(param.ids)
          .pipe(map((data) => StoresActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<StoreFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.storesService
          .create(params as Partial<StoreFormData>)
          .pipe(map((data) => StoresActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<StoreFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.storesService
          .update(params as Partial<StoreFormData>)
          .pipe(map((data) => StoresActions.updateSuccess({ data })));
      }),
    ),
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoresActions.image),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          files: {
            fileType: string;
            file: File;
            index: number;
            name: string;
          }[];
          folder: string;
        }) => params,
      ),
      mergeMap((params) => {
        return this.storesService
          .uploadImage(params)
          .pipe(map((data) => StoresActions.imageSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private storesService: StoresService,
  ) {}
}
