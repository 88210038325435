import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { MallList } from '../services/malls.service';
import { MallFormData } from '../interfaces/malls.interface';

export const MallsActions = createActionGroup({
  source: 'Malls Component',
  events: {
    list: props<MallList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    image: props<{
      files: {
        fileType: string;
        file: File;
        index: number;
      }[];
    }>(),
    imageSuccess: props<{ data: string[] }>(),
    imageFail: props<{ error: any }>(),
    mall: props<{ id: number }>(),
    mallSuccess: props<{ data: any }>(),
    mallFail: props<{ error: any }>(),
    streets: props<{ offset: number; limit: number }>(),
    streetsSuccess: props<{ data: any }>(),
    streetsFail: props<{ error: any }>(),
    filter: emptyProps(),
    filterSuccess: props<{ data: any }>(),
    filterFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<MallFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<MallFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
