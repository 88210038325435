// app.state.ts
import { ActionReducerMap } from '@ngrx/store';
import { loadingReducer } from './shared/components/loading/loading.reducer';

export interface AppState {
  loading: boolean;
}

export const reducers: ActionReducerMap<AppState> = {
  loading: loadingReducer
};
