import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RegisterAction } from './register.actions';

import { map, mergeMap } from 'rxjs/operators';
import { RegisterService } from './register.service';

@Injectable()
export class RegisterEffects {
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegisterAction.register),
      map(
        ({
          email,
          password,
        }: {
          email: string;
          password: string;
          type: string;
        }) => ({
          email,
          password,
        }),
      ),
      mergeMap((param) => {
        return this.registerService
          .register(param)
          .pipe(map((data) => RegisterAction.registerSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private registerService: RegisterService,
  ) {}
}
