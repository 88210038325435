import { createReducer, on } from '@ngrx/store';

import { ParkingActions } from './parking.actions';

export interface ParkingData {
  id: number;
  mall_id: number;
  car_number: number;
  created_on: Date;
  is_on_site: { type: string; data: number[] };
}

export interface ParkingState {
  parking: {
    message: string;
    data: {
      count: number;
      list: ParkingData[];
    };
    success: boolean;
  } | null;
  loading: boolean;
  error: any;
  deleteLoading: boolean;
  deleteParking: boolean;
  deleteError: any;
  createLoading: boolean;
  createParking: boolean;
  createError: any;
}

const initialState: ParkingState = {
  parking: null,
  loading: false,
  error: null,
  deleteLoading: false,
  deleteParking: false,
  deleteError: null,
  createLoading: false,
  createParking: false,
  createError: null,
};

export const parkingReducer = createReducer(
  initialState,
  on(ParkingActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(ParkingActions.listSuccess, (state, { data }) => ({
    ...state,
    parking: data,
    loading: true,
    error: null,
  })),
  on(ParkingActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(ParkingActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
  })),
  on(ParkingActions.deleteSuccess, (state) => ({
    ...state,
    deleteParking: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(ParkingActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
  })),
  on(ParkingActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
  })),
  on(ParkingActions.createSuccess, (state) => ({
    ...state,
    createParking: true,
    createLoading: true,
    createError: null,
  })),
  on(ParkingActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
  })),
);

export const parkingFeatureKey = 'parking';
