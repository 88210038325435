import { createReducer, on } from '@ngrx/store';

import { UsersActions } from './users.actions';
import { UsersState } from '../interfaces/users.interface';

const initialState: UsersState = {
  users: null,
  loading: false,
  error: null,
  user: null,
  userLoading: false,
  userError: null,
  deleteLoading: false,
  deleteUsers: false,
  deleteError: null,
  createLoading: false,
  createUsers: false,
  createError: null,
  updateLoading: false,
  updateUsers: false,
  updateError: null,
};

export const usersReducer = createReducer(
  initialState,
  on(UsersActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(UsersActions.listSuccess, (state, { data }) => ({
    ...state,
    users: data,
    loading: true,
    error: null,
  })),
  on(UsersActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(UsersActions.user, (state) => ({
    ...state,
    user: null,
    userLoading: false,
    userError: null,
  })),
  on(UsersActions.userSuccess, (state, { data }) => ({
    ...state,
    user: data,
    userLoading: true,
    userError: null,
  })),
  on(UsersActions.userFail, (state, { error }) => ({
    ...state,
    user: null,
    userLoading: false,
    userError: error,
  })),
  on(UsersActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteUsers: false,
  })),
  on(UsersActions.deleteSuccess, (state) => ({
    ...state,
    deleteUsers: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(UsersActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteUsers: false,
  })),
  on(UsersActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createUsers: false,
  })),
  on(UsersActions.createSuccess, (state) => ({
    ...state,
    createUsers: true,
    createLoading: true,
    createError: null,
  })),
  on(UsersActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createUsers: false,
  })),
  on(UsersActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateUsers: false,
  })),
  on(UsersActions.updateSuccess, (state) => ({
    ...state,
    updateUsers: true,
    updateLoading: true,
    updateError: null,
  })),
  on(UsersActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateUsers: false,
  })),
);

export const usersFeatureKey = 'users';
