import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ChainsActions } from './chains.actions';

import { map, mergeMap } from 'rxjs/operators';
import { ChainList, ChainsService } from '../services/chains.service';
import { ChainFormData } from '../interfaces/chains.interface';

@Injectable()
export class ChainsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.list),
      map(({ type: _type, ...params }: ChainList & { type: string }) => params),
      mergeMap((params) => {
        return this.chainsService
          .list(params)
          .pipe(map((data) => ChainsActions.listSuccess({ data })));
      }),
    ),
  );

  getChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.chain),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.chainsService
          .getChain(params)
          .pipe(map((data) => ChainsActions.chainSuccess({ data })));
      }),
    ),
  );

  suppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.suppliers),
      map(({ type: _type, ...params }) => params),
      mergeMap((params) => {
        return this.chainsService
          .suppliers(params)
          .pipe(map((data) => {
            return ChainsActions.suppliersSuccess({data})
          }));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.chainsService
          .delete(param.ids)
          .pipe(map((data) => ChainsActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<ChainFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.chainsService
          .create(params as Partial<ChainFormData>)
          .pipe(map((data) => ChainsActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChainsActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<ChainFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.chainsService
          .update(params as Partial<ChainFormData>)
          .pipe(map((data) => ChainsActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private chainsService: ChainsService,
  ) {}
}
