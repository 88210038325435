import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ParkingActions } from './parking.actions';

import { map, mergeMap } from 'rxjs/operators';
import { ParkingService } from './parking.service';

@Injectable()
export class ParkingEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingActions.list),
      map(
        ({
          offset,
          limit,
        }: {
          offset: number;
          limit: number;
          type: string;
        }) => ({
          offset,
          limit,
        }),
      ),
      mergeMap((param) => {
        return this.parkingService
          .list(param)
          .pipe(map((data) => ParkingActions.listSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingActions.delete),
      map(({ licensePlates }: { licensePlates: string[]; type: string }) => ({
        licensePlates,
      })),
      mergeMap((param) => {
        return this.parkingService
          .delete(param.licensePlates)
          .pipe(map((data) => ParkingActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingActions.create),
      map(({ licensePlates }: { licensePlates: string[]; type: string }) => ({
        licensePlates,
      })),
      mergeMap((param) => {
        return this.parkingService
          .create(param.licensePlates)
          .pipe(map((data) => ParkingActions.createSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private parkingService: ParkingService,
  ) {}
}
