import { createReducer, on } from '@ngrx/store';

import { CategoryActions } from './category.actions';
import { CategoryState } from '../interfaces/category.interface';

const initialState: CategoryState = {
  categories: null,
  loading: false,
  error: null,
  category: null,
  categoryLoading: false,
  categoryError: null,
  categoriesChannel: null,
  categoriesChannelLoading: false,
  categoriesChannelError: null,
  categoriesParent: null,
  categoriesParentLoading: false,
  categoriesParentError: null,
  deleteLoading: false,
  deleteCategories: false,
  deleteError: null,
  createLoading: false,
  createCategory: false,
  createError: null,
  updateLoading: false,
  updateCategory: false,
  updateError: null,
};

export const categoryReducer = createReducer(
  initialState,
  on(CategoryActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(CategoryActions.listSuccess, (state, { data }) => ({
    ...state,
    categories: data,
    loading: true,
    error: null,
  })),
  on(CategoryActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CategoryActions.category, (state) => ({
    ...state,
    category: null,
    categoryLoading: false,
    categoryError: null,
  })),
  on(CategoryActions.categorySuccess, (state, { data }) => ({
    ...state,
    category: data,
    categoryLoading: true,
    categoryError: null,
  })),
  on(CategoryActions.categoryFail, (state, { error }) => ({
    ...state,
    category: null,
    categoryLoading: false,
    categoryError: error,
  })),
  on(CategoryActions.categoriesChannel, (state) => ({
    ...state,
    categoriesChannel: null,
    categoriesChannelLoading: false,
    categoriesChannelError: null,
  })),
  on(CategoryActions.categoriesChannelSuccess, (state, { data }) => ({
    ...state,
    categoriesChannel: data,
    categoriesChannelLoading: true,
    categoriesChannelError: null,
  })),
  on(CategoryActions.categoriesChannelFail, (state, { error }) => ({
    ...state,
    categoriesChannel: null,
    categoriesChannelLoading: false,
    categoriesChannelError: error,
  })),
  on(CategoryActions.categoriesParent, (state) => ({
    ...state,
    categoriesParent: null,
    categoriesParentLoading: false,
    categoriesParentError: null,
  })),
  on(CategoryActions.categoriesParentSuccess, (state, { data }) => ({
    ...state,
    categoriesParent: data,
    categoriesParentLoading: true,
    categoriesParentError: null,
  })),
  on(CategoryActions.categoriesParentFail, (state, { error }) => ({
    ...state,
    categoriesParent: null,
    categoriesParentLoading: false,
    categoriesParentError: error,
  })),
  on(CategoryActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteCategories: false,
  })),
  on(CategoryActions.deleteSuccess, (state) => ({
    ...state,
    deleteCategories: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(CategoryActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteCategories: false,
  })),
  on(CategoryActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createCategory: false,
  })),
  on(CategoryActions.createSuccess, (state) => ({
    ...state,
    createCategory: true,
    createLoading: true,
    createError: null,
  })),
  on(CategoryActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createCategory: false,
  })),
  on(CategoryActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateCategory: false,
  })),
  on(CategoryActions.updateSuccess, (state) => ({
    ...state,
    updateCategory: true,
    updateLoading: true,
    updateError: null,
  })),
  on(CategoryActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateCategory: false,
  })),
);

export const categoryFeatureKey = 'category';
