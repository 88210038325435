import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MallsActions } from './malls.actions';

import { map, mergeMap } from 'rxjs/operators';
import { MallList, MallsService } from '../services/malls.service';
import { MallFormData } from '../interfaces/malls.interface';

@Injectable()
export class MallsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.list),
      map(({ type: _type, ...params }: MallList & { type: string }) => params),
      mergeMap((params) => {
        return this.mallsService
          .list(params)
          .pipe(map((data) => MallsActions.listSuccess({ data })));
      }),
    ),
  );

  getMall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.mall),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.mallsService
          .getMall(params)
          .pipe(map((data) => MallsActions.mallSuccess({ data })));
      }),
    ),
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.image),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          files: {
            fileType: string;
            file: File;
            index: number;
          }[];
        }) => params,
      ),
      mergeMap((params) => {
        return this.mallsService
          .uploadImage(params)
          .pipe(map((data) => MallsActions.imageSuccess({ data })));
      }),
    ),
  );

  streets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.streets),
      map(({ type: _type, ...params }) => params),
      mergeMap((params) => {
        return this.mallsService
          .streets(params)
          .pipe(map((data) => MallsActions.streetsSuccess({ data })));
      }),
    ),
  );

  filters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.filter),
      mergeMap(() => {
        return this.mallsService
          .filters()
          .pipe(map((data) => MallsActions.filterSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.mallsService
          .delete(param.ids)
          .pipe(map((data) => MallsActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<MallFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.mallsService
          .create(params as Partial<MallFormData>)
          .pipe(map((data) => MallsActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MallsActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<MallFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.mallsService
          .update(params as Partial<MallFormData>)
          .pipe(map((data) => MallsActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private mallsService: MallsService,
  ) {}
}
