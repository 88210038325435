import { CSSValue } from '../interfaces/css-value.type';

export enum VTheme {
  DEFAULT = 'v-theme-default',
  TEAL = 'v-theme-teal',
  RED = 'v-theme-red',
}

export enum VConfigName {
  apollo = 'apollo',
  zeus = 'zeus',
  hermes = 'hermes',
  poseidon = 'poseidon',
  ares = 'ares',
  ikaros = 'ikaros',
}

export enum VColorScheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface VConfig {
  id: VConfigName;
  name: string;

  bodyClass: string;
  imgSrc: string;
  direction: 'ltr' | 'rtl';
  style: {
    themeClassName: string;
    colorScheme: VColorScheme;
    borderRadius: CSSValue;
    button: {
      borderRadius: CSSValue | undefined;
    };
  };
  layout: 'vertical' | 'horizontal';
  boxed: boolean;
  sidenav: {
    title: string;
    imageUrl: string;
    showCollapsePin: boolean;
    user: {
      visible: boolean;
    };
    search: {
      visible: boolean;
    };
    state: 'expanded' | 'collapsed';
  };
  toolbar: {
    fixed: boolean;
    user: {
      visible: boolean;
    };
  };
  navbar: {
    position: 'below-toolbar' | 'in-toolbar';
  };
  footer: {
    visible: boolean;
    fixed: boolean;
  };
}

export type VConfigs = Record<VConfigName, VConfig>;

export interface VThemeProvider {
  name: string;
  className: string;
}
