
import { Pipe, PipeTransform } from '@angular/core';

const dicSettings = {
  dictionary: getLocDic(),
  waitingDic: false,
};

function getLocDic() {
  const dicStr = localStorage.getItem('dictionary');
  if (dicStr) return JSON.parse(dicStr);
  return {};
}
@Pipe({
  standalone: true,
  name: 'vTranslate',
})
export class VTranslatePipe implements PipeTransform {

  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }
    return dicSettings?.dictionary?.[value] || value;
  }
}
