import { createReducer, on } from '@ngrx/store';

import { EntityActions } from './orders.actions';
import { EntityState } from '../interfaces/orders.interface';

const initialState: EntityState = {
  rows: null,
  loading: false,
  error: null,
  entity: null,
  entityLoading: false,
  entityError: null,
  filterEntity: null,
  filterEntityLoading: false,
  filterEntityError: null,
  updateLoading: false,
  updateEntity: false,
  updateError: null,
};

export const ordersReducer = createReducer(
  initialState,
  on(EntityActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(EntityActions.listSuccess, (state, { data }) => ({
    ...state,
    rows: data,
    loading: true,
    error: null,
  })),
  on(EntityActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(EntityActions.entity, (state) => ({
    ...state,
    entity: null,
    entityLoading: false,
    entityError: null,
  })),
  on(EntityActions.entitySuccess, (state, { data }) => ({
    ...state,
    entity: data,
    entityLoading: true,
    entityError: null,
  })),
  on(EntityActions.entityFail, (state, { error }) => ({
    ...state,
    entity: null,
    entityLoading: false,
    entityError: error,
  })),
  on(EntityActions.filter, (state) => ({
    ...state,
    filterEntityLoading: false,
    filterEntityError: null,
  })),
  on(EntityActions.filterSuccess, (state, { data }) => ({
    ...state,
    filterEntity: data,
    filterEntityLoading: true,
    filterEntityError: null,
  })),
  on(EntityActions.filterFail, (state, { error }) => ({
    ...state,
    filterEntityLoading: false,
    filterEntityError: error,
  })),
);

export const ordersKey = 'orders';
