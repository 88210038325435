import { createActionGroup, props } from '@ngrx/store';

export const LoginAction = createActionGroup({
  source: 'Login Component',
  events: {
    login: props<{ email: string; password: string }>(),
    loginSuccess: props<{ data: any }>(),
    loginFail: props<{ error: any }>(),
    login2Fa: props<{ code: number , tmpToken: string }>(),
    login2FaSuccess: props<{ data: any }>(),
    login2FaFail: props<{ error: any }>(),
  },
});
