import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { UsersActions } from './users.actions';

import { map, mergeMap } from 'rxjs/operators';
import { UsersList, UsersService } from '../services/users.service';
import { UserFormData } from '../interfaces/users.interface';

@Injectable()
export class UsersEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.list),
      map(({ type: _type, ...params }: UsersList & { type: string }) => params),
      mergeMap((params) => {
        return this.usersService
          .list(params)
          .pipe(map((data) => UsersActions.listSuccess({ data })));
      }),
    ),
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.user),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.usersService
          .getUser(params)
          .pipe(map((data) => UsersActions.userSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<UserFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.usersService
          .create(params as Partial<UserFormData>)
          .pipe(map((data) => UsersActions.createSuccess(data)));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<UserFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.usersService
          .update(params as Partial<UserFormData>)
          .pipe(map((data) => UsersActions.updateSuccess(data)));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
  ) {}
}
