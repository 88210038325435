import { createReducer, on } from '@ngrx/store';
import { LoginAction } from './login.actions';

export interface LoginState {
  login: {
    message: string;
    user: { id: number; email: string; createdAt: Date; updatedAt: Date };
    accessToken: string;
  } | null;
  login2Fa: {
    message: string;
    user: { id: number; email: string; createdAt: Date; updatedAt: Date };
    accessToken: string;
  } | null;
  loading: boolean;
  error: any;
}

const initialState: LoginState = {
  login: null,
  login2Fa: null,
  loading: false,
  error: null,
};

export const loginReducer = createReducer(
  initialState,
  on(LoginAction.login, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(LoginAction.loginSuccess, (state, { data }) => ({
    ...state,
    login: data,
    loading: false,
    error: null,
  })),
  on(LoginAction.loginFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(LoginAction.login2Fa, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(LoginAction.login2FaSuccess, (state, { data }) => ({
    ...state,
    login2Fa: data,
    loading: false,
    error: null,
  })),
  on(LoginAction.login2FaFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export const loginFeatureKey = 'login';
