import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CategoryActions } from './category.actions';

import { map, mergeMap } from 'rxjs/operators';
import { CategoryList, CategoryService } from '../services/category.service';
import { CategoryFormData } from '../interfaces/category.interface';

@Injectable()
export class CategoryEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.list),
      map(
        ({ type: _type, ...params }: CategoryList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.categoryService
          .list(params)
          .pipe(map((data) => CategoryActions.listSuccess({ data })));
      }),
    ),
  );

  getCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.category),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.categoryService
          .getCategory(params)
          .pipe(map((data) => CategoryActions.categorySuccess({ data })));
      }),
    ),
  );

  getCategoriesChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.categoriesChannel),
      mergeMap(() => {
        return this.categoryService
          .getCategoriesChannel()
          .pipe(
            map((data) => CategoryActions.categoriesChannelSuccess({ data })),
          );
      }),
    ),
  );

  getCategoriesParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.categoriesParent),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          categoryId?: number;
          single?: boolean;
        }) => params,
      ),
      mergeMap((params) => {
        return this.categoryService
          .getCategoriesParents(params)
          .pipe(
            map((data) => CategoryActions.categoriesParentSuccess({ data })),
          );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.categoryService
          .delete(param.ids)
          .pipe(map((data) => CategoryActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CategoryFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.categoryService
          .create(params as Partial<CategoryFormData>)
          .pipe(map((data) => CategoryActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CategoryFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.categoryService
          .update(params as Partial<CategoryFormData>)
          .pipe(map((data) => CategoryActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private categoryService: CategoryService,
  ) {}
}
