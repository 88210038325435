import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { TableList } from '../services/orders.service';

export const EntityActions = createActionGroup({
  source: 'Orders Component',
  events: {
    list: props<TableList>(),
    itemsList: props<TableList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    image: props<{
      files: {
        fileType: string;
        file: File;
        index: number;
      }[];
    }>(),
    imageSuccess: props<{ data: string[] }>(),
    imageFail: props<{ error: any }>(),
    entity: props<{ id: number }>(),
    entitySuccess: props<{ data: any }>(),
    entityFail: props<{ error: any }>(),
    filter: emptyProps(),
    filterSuccess: props<{ data: any }>(),
    filterFail: props<{ error: any }>(),
  },
});
