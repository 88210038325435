// auth.service.ts

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,catchError, throwError, tap } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly storageKey = 'accessToken';
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
    AUTHORIZATION: '',
  });

  constructor(private http: HttpClient) {}

  setAccessToken(token: string): void {
    localStorage.setItem(this.storageKey, token);
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.storageKey);
  }

  isAccessTokenExpired(): boolean {
    const accessToken = this.getAccessToken();

    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);

        return !!decodedToken?.exp && decodedToken.exp < Date.now() / 1000;
      } catch (error) {
        return true;
      }
    }

    return true;
  }

  clearAccessToken(): void {
    localStorage.removeItem(this.storageKey);
  }

  async getTokenRole(roleNames: string[] = []): Promise<boolean> {
    try {
      const token = this.getAccessToken();
      if (!token) {
        return false;
      }
      const decodedToken = jwtDecode<{ role?: string[] }>(token);
      if (Array.isArray(decodedToken?.role)) {
        return roleNames.some(role => decodedToken.role!.includes(role));
      }
      return false;
    } catch (err) {
      return false;
    }
  }


  getMallTitle(roles: string[]) {
    try {
      const token = this.getAccessToken();
      if (!token) {
        return '';
      }
      const decodedToken = jwtDecode<{ role?: string; mall_title?: string }>(
        token,
      );
      if (!!decodedToken?.role && roles.includes(decodedToken?.role)) {
        return decodedToken.mall_title || '';
      }
      return '';
    } catch (err) {
      return '';
    }
  }

  refreshToken(): Observable<{ message: string; accessToken: string }> {
    return this.http
      .get<{ message: string; accessToken: string }>(
        `${environment.apiUrl}/user/refresh`,
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap((result) => {
          this.setAccessToken(result.accessToken);
        }),
        catchError((error) => {
          this.logout().subscribe();
          return throwError(error);
        })
      );
  }

  public logout(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/logout`, {
      withCredentials: true,
      headers: this.headers,
    }).pipe(
      tap(() => {
        localStorage.removeItem('accessToken');
      })
    );
  }
}
