import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ArticleInterface } from 'src/app/pages/pages/article/interfaces/article.interace';
import { ArticleService } from '../services/article.service';
import { articleActions } from './article.actions';
import { Router } from '@angular/router';

export const articleEffect = createEffect(
  (actions$ = inject(Actions), articleService = inject(ArticleService)) => {
    return actions$.pipe(
      ofType(articleActions.loadArticle),
      switchMap(({ slug }) =>
        articleService.getArticle(slug).pipe(
          map((article: ArticleInterface) => {
            console.log(article);
            return articleActions.loadArticleSuccess({ article });
          }),
          catchError(() => of(articleActions.loadArticleFailure()))
        )
      )
    );
  },
  { functional: true }
);

// export const deleteArticleEffect = createEffect(
//   (actions$ = inject(Actions), articleService = inject(ArticleService)) => {
//     return actions$.pipe(
//       ofType(articleActions.deleteArticle),
//       switchMap(({ slug }) =>
//         articleService.deleteArticle(slug).pipe(
//           map(() => {
//             return articleActions.deleteArticleSuccess();
//           }),
//           catchError(() => of(articleActions.deleteArticleFailure()))
//         )
//       )
//     );
//   },
//   { functional: true }
// );

export const redirectAfterDeleteEffect = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => {
    return actions$.pipe(
      ofType(articleActions.deleteArticleSuccess),
      tap(() => {
        router.navigateByUrl('/');
      })
    );
  },
  { functional: true, dispatch: false }
);
