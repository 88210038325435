import { createReducer, on } from '@ngrx/store';

import { MallsActions } from './malls.actions';
import { MallsState } from '../interfaces/malls.interface';

const initialState: MallsState = {
  malls: null,
  loading: false,
  error: null,
  streets: null,
  streetsLoading: false,
  streetsError: null,
  mall: null,
  mallLoading: false,
  mallError: null,
  image: null,
  imageLoading: false,
  imageError: null,
  filterMalls: null,
  filterMallsLoading: false,
  filterMallsError: null,
  deleteLoading: false,
  deleteMalls: false,
  deleteError: null,
  createLoading: false,
  createMalls: false,
  createError: null,
  updateLoading: false,
  updateMalls: false,
  updateError: null,
};

export const mallsReducer = createReducer(
  initialState,
  on(MallsActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(MallsActions.listSuccess, (state, { data }) => ({
    ...state,
    malls: data,
    loading: true,
    error: null,
  })),
  on(MallsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MallsActions.streets, (state) => ({
    ...state,
    streetsLoading: false,
    streetsError: null,
  })),
  on(MallsActions.streetsSuccess, (state, { data }) => ({
    ...state,
    streets: data,
    streetsLoading: true,
    streetsError: null,
  })),
  on(MallsActions.streetsFail, (state, { error }) => ({
    ...state,
    streetsLoading: false,
    streetsError: error,
  })),
  on(MallsActions.mall, (state) => ({
    ...state,
    mall: null,
    mallLoading: false,
    mallError: null,
  })),
  on(MallsActions.mallSuccess, (state, { data }) => ({
    ...state,
    mall: data,
    mallLoading: true,
    mallError: null,
  })),
  on(MallsActions.mallFail, (state, { error }) => ({
    ...state,
    mall: null,
    mallLoading: false,
    mallError: error,
  })),
  on(MallsActions.image, (state) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: null,
  })),
  on(MallsActions.imageSuccess, (state, { data }) => ({
    ...state,
    image: data,
    imageLoading: true,
    imageError: null,
  })),
  on(MallsActions.imageFail, (state, { error }) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: error,
  })),
  on(MallsActions.filter, (state) => ({
    ...state,
    filterMallsLoading: false,
    filterMallsError: null,
  })),
  on(MallsActions.filterSuccess, (state, { data }) => ({
    ...state,
    filterMalls: data,
    filterMallsLoading: true,
    filterMallsError: null,
  })),
  on(MallsActions.filterFail, (state, { error }) => ({
    ...state,
    filterMallsLoading: false,
    filterMallsError: error,
  })),
  on(MallsActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteMalls: false,
  })),
  on(MallsActions.deleteSuccess, (state) => ({
    ...state,
    deleteMalls: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(MallsActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteMalls: false,
  })),
  on(MallsActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createMalls: false,
  })),
  on(MallsActions.createSuccess, (state) => ({
    ...state,
    createMalls: true,
    createLoading: true,
    createError: null,
  })),
  on(MallsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createMalls: false,
  })),
  on(MallsActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateMalls: false,
  })),
  on(MallsActions.updateSuccess, (state) => ({
    ...state,
    updateMalls: true,
    updateLoading: true,
    updateError: null,
  })),
  on(MallsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateMalls: false,
  })),
);

export const mallsFeatureKey = 'malls';
