import { createActionGroup, props } from '@ngrx/store';

export const ParkingActions = createActionGroup({
  source: 'Parking Component',
  events: {
    list: props<{ offset: number; limit: number }>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    delete: props<{ licensePlates: string[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<{ licensePlates: string[] }>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
  },
});
