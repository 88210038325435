import { mergeDeep } from '../utils/merge-deep';
import {
  VColorScheme,
  VConfig,
  VConfigName,
  VConfigs,
  VTheme,
} from './v-config.interface';
import deepClone from '../utils/deep-clone';

const baseConfig: VConfig = {
  id: VConfigName.apollo,
  name: 'Apollo',
  bodyClass: 'v-layout-apollo',
  style: {
    themeClassName: VTheme.RED,
    colorScheme: VColorScheme.LIGHT,
    borderRadius: {
      value: 0.5,
      unit: 'rem',
    },
    button: {
      borderRadius: {
        value: 9999,
        unit: 'px',
      },
    },
  },
  direction: 'rtl',
  imgSrc: 'assets/img/logo/logo.svg',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'MYOFER',
    imageUrl: 'assets/img/logo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: false,
    },
    search: {
      visible: true,
    },
    state: 'expanded',
  },
  toolbar: {
    fixed: true,
    user: {
      visible: false,
    },
  },
  navbar: {
    position: 'below-toolbar',
  },
  footer: {
    visible: false,
    fixed: true,
  },
};

export const vConfigs: VConfigs = {
  apollo: baseConfig,
  poseidon: mergeDeep(deepClone(baseConfig), {
    id: VConfigName.poseidon,
    name: 'Poseidon',
    bodyClass: 'v-layout-poseidon',
    imgSrc: 'assets/img/logo/logo.svg',
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      user: {
        visible: false,
      },
    },
  }),
  hermes: mergeDeep(deepClone(baseConfig), {
    id: VConfigName.hermes,
    name: 'Hermes',
    bodyClass: 'v-layout-hermes',
    imgSrc: 'assets/img/logo/logo.svg',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    footer: {
      fixed: false,
    },
  }),
  ares: mergeDeep(deepClone(baseConfig), {
    id: VConfigName.ares,
    name: 'Ares',
    bodyClass: 'v-layout-ares',
    imgSrc: 'assets/img/logo/logo.svg',
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    navbar: {
      position: 'in-toolbar',
    },
    footer: {
      fixed: false,
    },
  }),
  zeus: mergeDeep(deepClone(baseConfig), {
    id: VConfigName.zeus,
    name: 'Zeus',
    bodyClass: 'v-layout-zeus',
    imgSrc: 'assets/img/logo/logo.svg',
    sidenav: {
      state: 'collapsed',
    },
  }),
  ikaros: mergeDeep(deepClone(baseConfig), {
    id: VConfigName.ikaros,
    name: 'Ikaros',
    bodyClass: 'v-layout-ikaros',
    imgSrc: 'assets/img/logo/logo.svg',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    navbar: {
      position: 'in-toolbar',
    },
    footer: {
      fixed: false,
    },
  }),
};
