import { createActionGroup, props } from '@ngrx/store';

export const RegisterAction = createActionGroup({
  source: 'Register Component',
  events: {
    register: props<{ email: string; password: string }>(),
    registerSuccess: props<{ data: any }>(),
    registerFail: props<{ error: any }>(),
  },
});
